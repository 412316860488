import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
// import * as AuthRole from './auth.role';
import { ToastrService } from 'ngx-toastr';
import { PlatformLocation } from '@angular/common';
import { LOCAL_FIELD } from '../common/constants/localstorage';
import { getUserInfo, isAuthenticated, IsInRole, logOut } from './auth.role';

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean => {
  const router = inject(Router);
  const toastService = inject(ToastrService);
  // const platformLocation = inject(PlatformLocation);
  // const PATHNAME = platformLocation.pathname;
  const userInfo = getUserInfo();

  const roles = route.data['roles'] as Array<string>;

  // If route's roles data includes user role, allow access, else prevent
  if (roles) {
    if (roles.includes(userInfo.Role)) {
      return true;
    }

    return false;
  }

  // If no roles are required, allow access
  if (!roles) {
    return true;
  }

  return true;

  if (!roles) {
    return true;
  }

  if (roles.length === 0) {
    toastService.error('Bạn không có quyền truy cập vào khu vực này');

    return false;
  }

  // if (roles === null || roles === undefined || roles.length === 0) {
  //   return true;
  // }

  if (isAuthenticated()) {
    const userInfo = getUserInfo();
    const check = IsInRole(userInfo, roles);

    if (!check) {
      toastService.error('Bạn không có quyền truy cập vào khu vực này');
      // alert('Bạn không có quyền truy cập vào khu vực này');
      if (router.url === '/login') {
        logOut();
      }

      router.navigateByUrl('/');
    }

    return check;
  } else {
    // if (PATHNAME.indexOf('print-data') !== -1) {
    //   router.navigate(['/login'], { queryParams: { back: PATHNAME } });
    // } else {
    //   if (window.location.pathname !== '/') {
    //     localStorage.setItem(LOCAL_FIELD.LAST_URL, window.location.href);
    //   }

    //   router.navigateByUrl('/login');
    // }

    return false;
  }
};

export const canActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => canActivate(route, state);
